import React from "react";
import ConfirmDialog from "../../components/Base/ConfirmDialog";
import { alertSuccess } from "../../store/actions/alert";
import { useDispatch } from "react-redux";
import { updateKycStatus } from "../../request/kyc-config";

const EnableSwapDisableFeatureForm = (props: any) => {
  const {
    isOpenEditPopupSwap,
    setIsOpenEditPopupSwap,
    disableButton,
    fetchFlags,
    swapFlag,
    swapFeatFlag,
    setSwapFeatFlag,
    displaySwapLabel,
    localSwapFlag,
  } = props;
  const dispatch = useDispatch();

  const handleSubmitPopup = async () => {
    const name = swapFlag?.name;
    try {
      const response = await updateKycStatus(
        { name, value: localSwapFlag },
        swapFlag?.id
      );

      fetchFlags();
      dispatch(alertSuccess("Successfull"));
      setIsOpenEditPopupSwap(false);
      setSwapFeatFlag(response.data.value);
    } catch (e) {
      console.log(e, "error");
    }
  };

  const handleCancel = () => {
    setIsOpenEditPopupSwap(false);
    setSwapFeatFlag(swapFeatFlag);
  };
  return (
    <ConfirmDialog
      title={
        displaySwapLabel ? "Disable Claim" : "Enable Claim"
      }
      open={isOpenEditPopupSwap}
      confirmLoading={disableButton}
      onConfirm={handleSubmitPopup}
      onCancel={handleCancel}
    >
      <div>Are you sure you want to {localSwapFlag ? "disable" : "enable"} Claim on KYC?</div>
    </ConfirmDialog>
  );
};

export default EnableSwapDisableFeatureForm;