import React, { useEffect } from "react";
import useStyles from "../style";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../utils/validate";
import { Switch } from "antd";
import { withRouter } from "react-router";

function DisplayTotalRaise(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, poolDetail,register } = props;
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail.hide_total_raise != undefined) {
      setValue("hide_total_raise", !!poolDetail.hide_total_raise);
    }
  }, [poolDetail]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <label className={classes.formControlLabel}>Display Total Raise</label>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControl component="fieldset">
          <Controller
            control={control}
            name="hide_total_raise"
            ref={register({ })}
            render={(field) => {
              const { value, onChange } = field;
              return (
                <Switch
                  onChange={async (switchValue) => {
                    await onChange(switchValue);
                  }}
                  checked={value}
                />
              );
            }}
          />
          {errors.hide_total_raise && (
            <p className={classes.formErrorMessage}>
              {renderError(errors, "hide_total_raise")}
            </p>
          )}
        </FormControl>
      </div>
      <br />
    </>
  );
}

export default withRouter(DisplayTotalRaise);
