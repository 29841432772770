import React, { useEffect, useState } from "react";
import useStyles from "../style";
import { Controller } from "react-hook-form";
import { DatePicker } from "antd";
import { useDispatch } from "react-redux";
import moment from "moment";
import { DATETIME_FORMAT, UTC_TIMEZONE } from "../../../constants";
import { withRouter } from "react-router";
import { makeLocalTimeInUTC } from "../../../utils/convertDate";

function DisplayScheduleTime(props: any) {
  const classes = useStyles();
  const {
    setValue,
    errors,
    control,
    poolDetail,
    isEditDisabled,
    watch,
    getValues,
  } = props;
  const renderError = (errors: any, name: string) =>
    errors[name]?.message || "";
  const dispatch = useDispatch();

  const [datePickerOpen, setDatePickerOpen] = useState(false); // State to control DatePicker

  useEffect(() => {
    if (poolDetail && poolDetail.schedule_time) {
      // Set the initial schedule time when the component loads
      const scheduleTime = moment.unix(poolDetail.schedule_time);
      setValue("schedule_time", makeLocalTimeInUTC(scheduleTime));
    }
  }, [poolDetail, setValue]);

  function handleDateSelection(field: any, date: any, validationObj: any = {}) {
    if (UTC_TIMEZONE) {
      const utcDate = date.toISOString();
      setValue(field, moment(utcDate), validationObj);
    } else {
      setValue(field, date, validationObj);
    }
  }

  function renderUtcSpan() {
    return (
      UTC_TIMEZONE && (
        <span
          style={{ fontWeight: "bold", fontSize: "12px", marginBottom: "3px" }}
        >
          UTC
        </span>
      )
    );
  }

  const [isInitailDateShift,setIsInitailDateShift] = useState(false)
  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <div className={classes.formControlFlexBlock}>
          <label className={classes.formControlLabel}>
            Pool Visibility Scheduled Time
          </label>

          {renderUtcSpan()}

          <div>
            <Controller
              control={control}
              name="schedule_time"
              defaultValue={null} // Default value can be null initially
              render={(field) => {
                const selectedDate = field.value ? new Date(field.value) : null;
                const { value, onChange } = field;
                return (
                  <DatePicker
                    value={
                      selectedDate
                        ? UTC_TIMEZONE
                          ? moment(selectedDate).utc()
                          : moment(selectedDate)
                        : null
                    }
              
                    onSelect={(datetimeSelected) => {
                      if (UTC_TIMEZONE && !getValues("schedule_time") && !isInitailDateShift) {
                        handleDateSelection(
                          field.name,
                          moment(datetimeSelected).add(1, "day")
                        );
                        setIsInitailDateShift(true);
                      } else {
                        handleDateSelection(field.name, datetimeSelected);
                      }
                    }}
                    open={datePickerOpen}
                    format="YYYY-MM-DD HH:mm:ss"
                    onChange={(date) => {
                      onChange(date); // Update the form value
                      setValue("schedule_time", date); // Ensure form state is updated
                    }}
                    showTime={{
                      defaultValue: moment("00:00:00", "HH:mm:ss"),
                      format: "HH:mm",
                    }}
                    inputReadOnly={UTC_TIMEZONE}
                    onOk={() => setDatePickerOpen(false)} // Close picker when the OK button is clicked
                    onOpenChange={setDatePickerOpen} // Handle opening/closing state
                    disabledDate={(current) =>
                      UTC_TIMEZONE
                        ? current && current < moment.utc().startOf("day")
                        : current && current < moment().startOf("day")
                    }
                    disabledTime={(current) => {
                      if (
                        current &&
                        current.isSame(
                          UTC_TIMEZONE ? moment.utc() : moment(),
                          "day"
                        )
                      ) {
                        const currentUTCHour = UTC_TIMEZONE
                          ? moment.utc().hour()
                          : moment().hour();
                        const currentUTCMinute = UTC_TIMEZONE
                          ? moment.utc().minute()
                          : moment().minute();
                        return {
                          disabledHours: () =>
                            Array.from(Array(24).keys()).filter(
                              (hour) => hour < currentUTCHour
                            ),
                          disabledMinutes: (selectedHour) =>
                            selectedHour === currentUTCHour
                              ? Array.from(Array(60).keys()).filter(
                                  (minute) => minute < currentUTCMinute
                                )
                              : [],
                        };
                      }
                      return {};
                    }}
                    minuteStep={5}
                  />
                );
              }}
            />
          </div>
          <div
            className={`${classes.formErrorMessage} ${classes.formErrorMessageAbsolute}`}
          >
            {renderError(errors, "schedule_time")}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(DisplayScheduleTime);
