import { apiRoute } from "../utils";
import { BaseRequest } from "./Request";

export const getFlag = async () => {
  const baseRequest = new BaseRequest();
  let url = "flags"
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const updateKycStatus = async (
  tiers: { name: any; value: boolean },
  Id: number
) => {
  const baseRequest = new BaseRequest();
  let url = `/flags/${Id}`;
  const response = (await baseRequest.patch(url, tiers)) as any;
  return await response?.json();
};

