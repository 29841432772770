import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { FormControl, Switch } from "@material-ui/core";
import { alertSuccess } from "../../store/actions/alert";
import { useDispatch } from "react-redux";
import { getFlag } from "../../request/kyc-config";

import EnableSwapDisableFeatureForm from "./EnableSwapDisableFeatureForm";

const Config = () => {

  const [swapFeatFlag, setSwapFeatFlag] = useState(true);
  const [swapFlag, setSwapFlag] = useState("");

  const [isOpenEditPopupSwap, setIsOpenEditPopupSwap] =
    useState<boolean>(false);
  const [displaySwapLabel, setDisplaySwapLabel] = useState(true);
  const [localSwapFlag, setLocalSwapFlag] = useState(true);

  const handleSwapDisableChange = async (event: any) => {
    const { checked } = event?.target;
    const value: any = checked;
    setLocalSwapFlag(value);
    setIsOpenEditPopupSwap(true);
    setDisplaySwapLabel(checked);
  };

  const fetchFlags = async () => {
    try {
      const response = await getFlag();
      const flags = response.data;
   
      const disableSwapFlag = flags?.find(
        (flag: any) => flag?.name === "Disable-Claim-On-Kyc"
      );

      if (response?.status === 200) {
        setSwapFlag(disableSwapFlag);
        setSwapFeatFlag(disableSwapFlag.value)
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFlags();
  }, []);


  return (
    <DefaultLayout>

        <div>
      
          <EnableSwapDisableFeatureForm
            fetchFlags={fetchFlags}
            swapFlag={swapFlag}
            isOpenEditPopupSwap={isOpenEditPopupSwap}
            setIsOpenEditPopupSwap={setIsOpenEditPopupSwap}
            swapFeatFlag={swapFeatFlag}
            setSwapFeatFlag={setSwapFeatFlag}
            displaySwapLabel={displaySwapLabel}
            localSwapFlag={localSwapFlag}
          />


 
          <FormControl style={{ flexDirection: "row", alignItems: "center", marginTop: "10px" }}>
            <Switch
              onChange={(e) => handleSwapDisableChange(e)}
              checked={swapFeatFlag ? true : false}
            />
            <h4
              style={{
                marginRight: "10px",
                marginTop: "5px",
                fontSize: "14px",
              }}
            >
              Disable Claim for Forbidden Countries
            </h4>
          </FormControl>
        </div>
    
    </DefaultLayout>
  );
};

export default Config;